<template>
  <ValidationProvider tag="div" class="single-answer column is-half" :rules="validationRules" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field>
          <b-select placeholder="Click to select..." @input="onChange">
            <option
                v-for="answer in question.answers"
                :value="answer.code"
                :key="answer.id">
              {{ answer.answer[lang] }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>

import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'DropdownList',
  mixins: [QuestionMixin],
  methods: {
    onChange (value) {
      this.$emit('input', { [this.question.qid]: `${value}` })
    }
  }
}
</script>
